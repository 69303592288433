import Glide from '@glidejs/glide';
import { init as bookingInit, openModule as bookingOpenModule } from "https://beautyprosoftware.com/online-booking-init/index.js";

window.addEventListener('load', () => {
    bookingInit({
        database: 869480,
    });

    const initFixedHeader = () => {
        const header = document.querySelector('.header');
        window.addEventListener('scroll', () => {
            const position = window.scrollY;
            if (position > 80) {
                header.classList.add('header--fixed');
                return null;
            }
            header.classList.remove('header--fixed');
        })
    };

    const initBookingOpen = () => {
        const links = Array.from(document.querySelectorAll('.js-booking-open'));
        links.forEach((link) => {
            link.addEventListener('click', (e) => {
                e.preventDefault();
                bookingOpenModule();
            });
        })
    };

    const initLangSwitch = () => {
        const langSwitchArray = Array.from(document.querySelectorAll('.lang-switch'));
        if (langSwitchArray) {
            langSwitchArray.forEach((langSwitch) => {
                const langToggler = langSwitch.querySelector('.lang-switch__current');
                langToggler.addEventListener('click', (e) => {
                    langSwitch.classList.toggle('lang-switch--open');
                });
            });
        }
    };

    const initScrollTop = () => {
        const btn = document.querySelector('.footer__up');
        window.addEventListener('scroll', () => {
            if(window.scrollY > 1700) {
                btn.classList.add('footer__up--shown');
                return;
            }
            btn.classList.remove('footer__up--shown');
        });
        btn.addEventListener('click', () => {
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        });
    };

    const initToggler = () => {
        const header = document.querySelector('.header');
        if(header) {
            const toggler = header.querySelector('.header__toggler');
            const body = document.querySelector('body');
            const backdrop = header.querySelector('.header__backdrop');
            backdrop.addEventListener('click', () => {
                header.classList.remove('header--open');
                body.classList.remove('header-open');
            });
            toggler.addEventListener('click', () => {
                header.classList.toggle('header--open');
                body.classList.toggle('header-open');
            })
        }
    };

    const initBtnAnimation = () => {
        const btns = Array.from(document.querySelectorAll('.btn'));
        if(btns) {
            btns.forEach((btn) => {
                const circle = btn.querySelector('.btn__circle');
                const offset = btn.getBoundingClientRect();
                if(circle) {
                    btn.addEventListener('mouseenter', (e) => {
                        const relX = e.pageX - offset.left;
                        const relY = e.clientY - offset.top;
                        circle.style.left = `${relX}px`;
                        circle.style.top = `${relY}px`;
                        circle.classList.remove('desplode-circle');
                        circle.classList.add("explode-circle");
                    });
                    btn.addEventListener('mouseleave', (e) => {
                        const relX = e.pageX - offset.left;
                        const relY = e.clientY - offset.top;
                        circle.style.left = `${relX}px`;
                        circle.style.top = `${relY}px`;
                        circle.classList.add('desplode-circle');
                        circle.classList.remove("explode-circle");
                    });
                }
            })
        }
    };

    const initSlider = () => {
        const slider = document.querySelector('.glide');
        if(slider) {
            new Glide('.glide', {
                type: 'carousel',
                startAt: 0,
                perView: 1,
                gap: 70,
                peek: {before: 0, after: 0},
            }).mount();
        }
    };

    const initHeroBgVideo = () => {
        const section = document.querySelector('.hero');
        if(section) {
            const src = section.dataset.video;
            if(src && screen.width > 1200) {
                const video = document.createElement('video');
                video.autoplay = true;
                video.muted = true;
                video.loop = true;
                video.classList.add('hero__video');
                video.innerHTML = ` <source src="${src}" type="video/mp4">`;
                section.append(video);
            }
        }
    };

    const initServicesMore = () => {
        const btn = document.querySelector('.service-page__more');
        btn.addEventListener('click', () => {
            const content = document.querySelector('.service-page__content');
            window.scrollTo({
                top: content.offsetTop - 50,
                behavior: 'smooth',
            });
        })
    };

    initBookingOpen();
    initFixedHeader();
    initToggler();
    initLangSwitch();
    initSlider();
    initBtnAnimation();
    initScrollTop();
    initHeroBgVideo();
    initServicesMore();
});